import React, { useEffect, useState } from "react";
import { json, useParams } from "react-router-dom";
import { fetchData } from "../../api/api";
import "./ViewApplicants.css";
import ApplicantsTable from "./components/ApplicantsTable";
import Pagination from "./components/Pagination";
import ApplicantsSearch from "./components/ApplicantsSearch"; // Import the search component

const ViewApplicants = () => {
  const { jobId } = useParams();
  const [jobDetails, setJobDetails] = useState({});
  const [applicants, setApplicants] = useState([]);
  const [filteredApplicants, setFilteredApplicants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [applicantsPerPage] = useState(5);

  useEffect(() => {
    const getApplicantsDetails = async () => {
      setIsLoading(true);
      try {
        const response = await fetchData(
          "EmployerRoutes/getApplicantsDetails",
          "POST",
          { jobId }
        );
        if (response.success) {
          setJobDetails({
            title: response.job.jobTitle,
            employmentType: response.job.employmentType,
            experience: response.job.experience,
            jobDescription: response.job.jobDescription,
          });
          setApplicants(response.job.applicants);
          setFilteredApplicants(response.job.applicants);
        } else {
          console.error("Failed to fetch applicants:", response.message);
        }
      } catch (error) {
        console.error("Error fetching applicants:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getApplicantsDetails();
  }, [jobId]);

  const handleSearch = (searchTerm) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    if (!searchTerm) {
      setFilteredApplicants(applicants);
    } else {
      const filtered = applicants.filter((applicant) => {
        const isNameMatch = applicant.fullname
          .toLowerCase()
          .includes(lowerCaseSearchTerm);
        const isEmailMatch = applicant.email
          .toLowerCase()
          .includes(lowerCaseSearchTerm);
        const isJobTitleMatch = applicant.employeeProfile?.job_title
          .toLowerCase()
          .includes(lowerCaseSearchTerm);

        // Assuming experience and contact are numbers or should be matched exactly, you might want to keep them as is.
        // If they're not, convert them to strings and use toLowerCase() for a case-insensitive partial match.
        const isExperienceMatch =
          applicant.employeeProfile?.experience === searchTerm;
        const isContactMatch =
          applicant.employeeProfile?.contact.includes(searchTerm);

        return (
          isNameMatch ||
          isEmailMatch ||
          isJobTitleMatch ||
          isExperienceMatch ||
          isContactMatch
        );
      });
      setFilteredApplicants(filtered);
    }
  };

  const indexOfLastApplicant = currentPage * applicantsPerPage;
  const indexOfFirstApplicant = indexOfLastApplicant - applicantsPerPage;
  const currentApplicants = filteredApplicants.slice(
    indexOfFirstApplicant,
    indexOfLastApplicant
  );
  const totalPages = Math.ceil(filteredApplicants.length / applicantsPerPage);

  return (
    <div className="bg-white p-5 rounded-lg">
      <h1>All applicants for the job</h1>

      {!isLoading && (
        <div className="job-details-container">
          <div className="job-title">
            <h2>{jobDetails.title}</h2>
            <span className="experience-badge">
              <i className="fas fa-briefcase text-white"></i>{" "}
              {jobDetails.experience} years experience
            </span>
          </div>
          <hr />
          <div className="job-description">
            <h4>
              <i className="fas fa-align-left"></i> Description
            </h4>
            <p>{jobDetails.jobDescription}</p>
          </div>
          <div className="job-footer">
            <i className="fas fa-user-tie"></i> Employment Type:{" "}
            {jobDetails.employmentType}
          </div>
        </div>
      )}

      {isLoading ? (
        <p className="text-center">Loading applicants...</p>
      ) : (
        <>
          <ApplicantsSearch onSearch={handleSearch} />
          {isLoading ? (
            <p className="text-center">Loading applicants...</p>
          ) : currentApplicants.length > 0 ? (
            <>
              <ApplicantsTable applicants={currentApplicants} />
              <Pagination totalPages={totalPages} paginate={setCurrentPage} />
            </>
          ) : (
            <p className="text-center">No applicants found.</p>
          )}
        </>
      )}
    </div>
  );
};

export default ViewApplicants;
