import React from "react";

const RecentBlog = () => {
  // Assuming you have a list of recent blogs or fetch them from an API
  const recentBlogs = [
    {
      url: "https://linkagekoworks.viewourdesign.info/resources/how-the-great-american-resignation-gives-rise-to-an-industry-of-virtual-assistants-in-the-philippines",
      img: "https://linkagekoworks.viewourdesign.info/storage/images/r1-img.jpg",
      title:
        "How The Great American Resignation Gives Rise to an Industry of Virtual Assistants in the Philippines",
      date: "May 19, 2022",
    },
    // Add more blogs as needed
  ];

  return (
    <div className="recent-blog">
      <h3 className="blue">Recent Blog</h3>
      <ul className="recent-list">
        {recentBlogs.map((blog, index) => (
          <li key={index} className="row align-items-center">
            <div className="col col-4 bp-img">
              <a href={blog.url}>
                <img src={blog.img} alt={blog.title} />
              </a>
            </div>
            <div className="col col-8 bp-content">
              <h4>
                <a href={blog.url}>{blog.title}</a>
              </h4>
              <span className="bp-date">{blog.date}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentBlog;
