import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { fetchData } from "../../api/api";
import "./ViewAllMarkWorkers.scss"; // Adjust the path based on your file structure
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const ViewAllMarkWorkers = () => {
  const [bookmarkedWorkers, setBookmarkedWorkers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getBookmarkedWorkers = async () => {
      setIsLoading(true);
      const response = await fetchData(
        "EmployerRoutes/getPinnedEmployees",
        "GET"
      );
      if (response && response.pinnedEmployees) {
        setBookmarkedWorkers(response.pinnedEmployees);
      }
      setIsLoading(false);
    };
    getBookmarkedWorkers();
  }, []);

  const extractSkills = (skillsObject) => {
    let allSkills = [];
    Object.values(skillsObject).forEach((category) => {
      if (Array.isArray(category)) {
        category.forEach((skill) => {
          if (skill && skill.name) allSkills.push(skill.name);
        });
      }
    });
    return allSkills.sort(() => 0.5 - Math.random()).slice(0, 10);
  };

  const navigateToProfile = (worker) => {
    navigate("/ViewOneEmployee", { state: { user: worker.employee } });
  };

  const handleEdit = async (e, workerId, notes) => {
    e.stopPropagation();
    const updatedNotes = prompt("Edit notes:", notes);
    if (updatedNotes !== null && updatedNotes !== notes) {
      await fetchData("EmployerRoutes/editPinNotes", "POST", {
        pinId: workerId,
        notes: updatedNotes,
      });
      setBookmarkedWorkers(
        bookmarkedWorkers.map((worker) =>
          worker.employee._id === workerId
            ? { ...worker, notes: updatedNotes }
            : worker
        )
      );
    }
  };

  const handleDelete = async (e, workerId) => {
    e.stopPropagation();
    const isConfirmed = window.confirm(
      "Are you sure you want to unpin this employee?"
    );
    if (isConfirmed) {
      await fetchData("EmployerRoutes/unpinEmployee", "DELETE", {
        employeeId: workerId,
      });
      setBookmarkedWorkers(
        bookmarkedWorkers.filter((worker) => worker.employee._id !== workerId)
      );
    }
  };

  return (
    <section className="content-section employer-dashboard white-bg">
      <div className="container k-container">
        <div className="row">
          <div className="col px-0">
            <div className="your-bookmarks">
              <h2 className="f-35 blue mbot-40">All Bookmarked Workers</h2>
              {isLoading ? (
                <p>Loading...</p>
              ) : (
                <div className="scrollable-section">
                  {bookmarkedWorkers.map((worker, index) => (
                    <div className="bookmark-content" key={index}>
                      <div
                        className="bookmark-header"
                        onClick={() => navigateToProfile(worker)}
                        style={{ cursor: "pointer" }}
                      >
                        <h4 className="white">{worker.employee.fullname}</h4>
                        <h5 className="white">
                          {worker.employee.employeeProfile.job_title}
                        </h5>
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="edit-icon white"
                          size="lg"
                          style={{ margin: "0 10px", cursor: "pointer" }}
                          onClick={(e) => handleEdit(e, worker.employee._id)}
                        />
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="trash-icon white"
                          size="lg"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => handleDelete(e, worker.employee._id)}
                        />
                      </div>

                      <div className="row align-items-center bookmark-data">
                        <div className="col-2">
                          <img
                            src="https://linkagekoworks.viewourdesign.info/storage/images/placeholder-user.png"
                            alt={worker.employee.fullname}
                          />
                        </div>
                        <div className="col-10">
                          <div className="row">
                            <div className="col">
                              <h6 className="blue">Expected Salary</h6>
                              <p className="b">
                                ${worker.employee.employeeProfile.salary}
                              </p>
                            </div>
                            <div className="col">
                              <h6 className="blue">Education</h6>
                              <p className="up-val">
                                {worker.employee.employeeProfile.education}
                              </p>
                            </div>
                            <div className="col">
                              <h6 className="blue">Experience</h6>
                              <p className="b">
                                {worker.employee.employeeProfile.experience}{" "}
                                Years
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <h6 className="blue">Top Skills</h6>
                              <ul className="tskills">
                                {extractSkills(worker.employee.skill).map(
                                  (skill, skillIndex) => (
                                    <li key={skillIndex} className="b">
                                      {skill}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <h6 className="blue">Notes:</h6>
                              <p>{worker.notes}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewAllMarkWorkers;
