import React, { useState } from "react";

const ApplicantsSearch = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    onSearch(event.target.value); // Trigger the search in parent component
  };

  return (
    <div className="search-container">
      <input
        type="text"
        placeholder="Search applicants..."
        value={searchTerm}
        onChange={handleInputChange}
        className="search-input"
      />
    </div>
  );
};

export default ApplicantsSearch;
