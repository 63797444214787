import React from "react";
import logo from "../../imgs/linkage-logo.png";
import FooterColumn from "./components/FooterColumn";
import { Link } from "react-router-dom";

const Footer = () => {
  const columns = [
    {
      title: "Employers",
      links: [
        { href: "/HowItWorks/employer", text: "How It Works" },
        { href: "/Signup", text: "Register" },
        { href: "/PostAJob", text: "Post a Job" },
        { href: "/Blog", text: "Blog" },
      ],
    },
    {
      title: "Workers",
      links: [
        { href: "/HowItWorks/jobseeker", text: "How It Works" },
        { href: "/Signup", text: "Register" },
        { href: "/SearchJob", text: "Post your skills" }, // Adjust if there's a specific route for posting skills
        { href: "/Jobseek", text: "Job Search" },
        { href: "/Blog", text: "Life as Virtual Assistant" }, // Assuming you have a route for this, if not, adjust accordingly
      ],
    },
    {
      title: "Talented VA's",
      links: [
        { href: "/SearchEmployee?s=SEO", text: "SEO VA's" },
        { href: "/SearchEmployee?s=WordPress", text: "WordPress Experts" },
        {
          href: "/SearchEmployee?s=GraphicDesign",
          text: "Graphic Designers",
        },
        {
          href: "/SearchEmployee?s=ContentWriting",
          text: "Content Writers",
        },
        { href: "/SearchEmployee?s=Bookkeeping", text: "Bookkeeping VA's" },
        {
          href: "/SearchEmployee?s=Developer",
          text: "Web Developer",
        },
      ],
    },
    {
      title: "Quick Links",
      links: [
        { href: "/FAQ", text: "FAQ" }, // Adjust if you have a route for FAQ
        { href: "/AboutUs", text: "About Us" }, // Assuming you have a route for About Us, if not, adjust accordingly
        { href: "/ContactUs", text: "Contact Us" }, // Assuming you have a route for Contact Us, if not, adjust accordingly
        { href: "/PrivacyPolicy", text: "Privacy Policy" }, // Assuming you have a route for Privacy Policy, if not, adjust accordingly
        { href: "/TermsOfUse", text: "Terms of Use" }, // Assuming you have a route for Terms of Use, if not, adjust accordingly
      ],
    },
  ];

  return (
    <footer>
      <section className="top-footer">
        <div className="container k-container">
          <div className="row">
            {columns.map((column, index) => (
              <FooterColumn
                key={index}
                title={column.title}
                links={column.links}
              />
            ))}
          </div>
        </div>
      </section>
      <section className="bottom-footer">
        <div className="container k-container">
          <div className="row align-items-center">
            <div className="col col-md-6">
              <Link to="/">
                <img src={logo} alt="Linkage Logo" />
              </Link>
            </div>
            <div className="col col-md-6 text-center">
              <p className="copy">
                Copyright © 2023 Linkage.ph. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
