import React from "react";

const NavigationBlog = () => {
  return (
    <div className="nav-blog">
      <div className="row">
        <div className="col text-left">
          <div className="nb-img">
            <a href="https://linkagekoworks.viewourdesign.info/resources/how-the-great-american-resignation-gives-rise-to-an-industry-of-virtual-assistants-in-the-philippines">
              <img
                src="https://linkagekoworks.viewourdesign.info/storage/images/r1-img.jpg"
                alt="How The Great American Resignation Gives Rise to an Industry of Virtual Assistants in the Philippines"
              />
            </a>
          </div>
          <div className="nb-content nbc-prev">
            <a
              href="https://linkagekoworks.viewourdesign.info/resources/how-the-great-american-resignation-gives-rise-to-an-industry-of-virtual-assistants-in-the-philippines"
              className="nb-nav nb-nav-prev"
            >
              Prev Post
            </a>
            <a href="https://linkagekoworks.viewourdesign.info/resources/how-the-great-american-resignation-gives-rise-to-an-industry-of-virtual-assistants-in-the-philippines">
              <h4>
                How The Great American Resignation Gives Rise to an Industry of
                Virtual Assistants in the Philippines
              </h4>
            </a>
          </div>
        </div>
        <div className="col text-right">
          <div className="nb-content nbc-next">
            <a
              href="https://linkagekoworks.viewourdesign.info/resources/5-best-reasons-why-you-should-hire-a-filipino-virtual-assistant"
              className="nb-nav nb-nav-next"
            >
              Next Post
            </a>
            <a href="https://linkagekoworks.viewourdesign.info/resources/5-best-reasons-why-you-should-hire-a-filipino-virtual-assistant">
              <h4>
                5 Best Reasons Why you Should hire a Filipino Virtual Assistant
              </h4>
            </a>
          </div>
          <div className="nb-img">
            <a href="https://linkagekoworks.viewourdesign.info/resources/5-best-reasons-why-you-should-hire-a-filipino-virtual-assistant">
              <img
                src="https://linkagekoworks.viewourdesign.info/storage/images/r3-img.jpg"
                alt="5 Best Reasons Why you Should hire a Filipino Virtual Assistant"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigationBlog;
