import React from "react";

const ResourceSearch = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement search logic here
    console.log("Search submitted");
  };

  return (
    <div className="resource-search">
      <form onSubmit={handleSubmit}>
        <input type="text" name="keyword" placeholder="Search" required />
        <button type="submit">
          <i className="fa fa-search" aria-hidden="true"></i>
        </button>
      </form>
    </div>
  );
};

export default ResourceSearch;
