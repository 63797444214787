const Faq = () => {
  return (
    <>
      <section className="banner-section-sa banner-page banner-faq">
        <div className="container k-container">
          <div className="row">
            <div className="col text-center">
              <div className="banner-content">
                <p className="banner-title-sub red-bg">
                  100% MONEY BACK GUARANTEE
                </p>
                <h1 className="banner-title">
                  Upgrade to Communicate with Workers
                </h1>
                <p>Directly hire VA's and start growing your business!</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section content-testimonials white-bg">
        <div className="container k-container">
          <div className="row">
            <div className="col text-center">
              <h2 className="content-title f-45 blue">
                Frequently Asked Questions (Jobseekers)
              </h2>
            </div>
          </div>
          <div className="row mtop-90">
            <div className="col">
              <h3 className="blue">Becoming a Linkage applicant</h3>
              <div className="accordion mtop-30" id="faqAP">
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqOne"
                    aria-expanded="true"
                    aria-controls="faqOne"
                  >
                    Do I need a college degree to apply for a job?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAP"
                  >
                    <div className="accordion-body">
                      <p>
                        A university diploma is a plus, but it is not required.
                        You will be accommodated as long as you meet the minimum
                        requirements for a certain position.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqTwo"
                    aria-expanded="false"
                    aria-controls="faqTwo"
                  >
                    Do you accept fresh graduates?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#faqAP"
                  >
                    <div className="accordion-body">
                      <p>
                        YES! On the platform, there are thousands of firms
                        looking to hire people with a variety of skills.
                        Hundreds of thousands of people have found work through
                        Linkage, and we've only had a few instances when someone
                        was unable to find a job.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqThree"
                    aria-expanded="false"
                    aria-controls="faqThree"
                  >
                    Do you have any age limit?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#faqAP"
                  >
                    <div className="accordion-body">
                      <p>
                        We follow the clients' individual requirements and
                        preferences. As long as you’re above 18 and legally
                        permitted to work. Age isn't a consideration.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqFour"
                    aria-expanded="false"
                    aria-controls="faqFour"
                  >
                    Is there going to be a job interview?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#faqAP"
                  >
                    <div className="accordion-body">
                      <p>
                        There will be job interviews, of course. Depending on
                        your employer, it could be by email, Skype call, or
                        video call.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mtop-45">
            <div className="col">
              <h3 className="blue">
                Building your profile and finding clients
              </h3>
              <div className="accordion mtop-30" id="faqCS">
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCSOne"
                    aria-expanded="true"
                    aria-controls="faqCSOne"
                  >
                    How will I get clients?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqCSOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqCS"
                  >
                    <div className="accordion-body">
                      <p>
                        To get an employer or an online job, you need to sign up
                        for a Linkage account. Please provide as much
                        information that you can; the more information, the more
                        chances you have of getting hired.
                      </p>
                      <p>
                        Once your profile is ready, you can apply for jobs ,
                        which is more preferable, or wait for employers to
                        contact you.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCSTwo"
                    aria-expanded="false"
                    aria-controls="faqCSTwo"
                  >
                    What happens if I make a false statement on my profile?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqCSTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#faqCS"
                  >
                    <div className="accordion-body">
                      <p>
                        We have real individuals manually checking the profiles,
                        and we make every effort to verify that all of the
                        information on the site is accurate. However, there are
                        times when this is not discovered until it is too late.
                        You risk getting banned from Linkage if you are
                        detected, so be sure all of the information in your
                        profile is accurate.
                      </p>
                      <p>
                        Even if you don't get discovered (which is unusual), you
                        might land a job with an employer that will be
                        disappointed in your work since you didn't have the
                        talents you listed on your resume. The employer is
                        unlikely to pay for work that has (or has not) been
                        completed.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCSThree"
                    aria-expanded="false"
                    aria-controls="faqCSThree"
                  >
                    How long will it take before I get hired?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqCSThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#faqCS"
                  >
                    <div className="accordion-body">
                      <p>
                        This is all up to you. You can wait for an employer to
                        hire you, but this may take some time. Applying for
                        several jobs that you are qualified for and responding
                        quickly to queries is the fastest method to get hired.
                        Only apply for jobs that are a suitable match for your
                        talents and expertise. This is so you don't waste time
                        applying for jobs you don't stand a chance of getting.
                      </p>
                      <p>
                        It also aids in the application process for a variety of
                        employment. Consider your fundamental abilities and how
                        they might be used in a variety of other, if slightly
                        different, careers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mtop-45">
            <div className="col">
              <h3 className="blue">Communicating with clients</h3>
              <div className="accordion mtop-30" id="faqCC">
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCCOne"
                    aria-expanded="true"
                    aria-controls="faqCCOne"
                  >
                    Should I be fluent in English to apply for a job?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqCCOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqCC"
                  >
                    <div className="accordion-body">
                      <p>
                        No, however excellent English will give you an
                        advantage. The site's majority of employers are from the
                        United States, the United Kingdom, or Australia (among
                        others). Remember that all interaction will be done in
                        English, so if you have trouble understanding or
                        expressing yourself in English, it may be a little more
                        challenging for you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mtop-45">
            <div className="col">
              <h3 className="blue">Getting paid</h3>
              <div className="accordion mtop-30" id="faqGP">
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqGPOne"
                    aria-expanded="true"
                    aria-controls="faqGPOne"
                  >
                    How much is the salary?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqGPOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqGP"
                  >
                    <div className="accordion-body">
                      <p>
                        The salary package you'll receive will be determined by
                        your background, job experience, and qualifications as
                        they relate to the position you’re hiring for.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqGPTwo"
                    aria-expanded="true"
                    aria-controls="faqGPTwo"
                  >
                    How do I get paid?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqGPTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqGP"
                  >
                    <div className="accordion-body">
                      <p>
                        Employers pay their employees directly, rather than
                        through Linkage. We do not collect a percentage of
                        salary payments as a charge. As a result, how workers
                        are compensated is decided by both the company and the
                        employee.
                      </p>
                      <p>
                        There are numerous ways for you to get paid. Depending
                        on the service you use, you may even be able to get your
                        money at home.
                      </p>
                      <p>
                        Here are a few websites where your boss can pay you via
                        the internet.
                      </p>
                      <ul>
                        <li>Easypay</li>
                        <li>Payoneer</li>
                        <li>PayPal</li>
                        <li>
                          Western Union is an international money transfer
                          service.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqGPThree"
                    aria-expanded="true"
                    aria-controls="faqGPThree"
                  >
                    When do I get paid?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqGPThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqGP"
                  >
                    <div className="accordion-body">
                      <p>
                        It all depends on the terms of your contract with your
                        employer. You will usually get paid every two weeks or
                        once a month. Workers should, however, request weekly
                        pay when they are first hired to reduce the danger of
                        working for a new business.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mtop-45">
            <div className="col">
              <h3 className="blue">Support</h3>
              <div className="accordion mtop-30" id="faqSP">
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqSPOne"
                    aria-expanded="true"
                    aria-controls="faqSPOne"
                  >
                    Will I have to install any software in order to utilize
                    Linkage KoWorks?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqSPOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqSP"
                  >
                    <div className="accordion-body">
                      <p>
                        No, other than an Internet browser, Linkage does not
                        require any software to be purchased or placed onto your
                        computer in order to utilize our service.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqSPTwo"
                    aria-expanded="true"
                    aria-controls="faqSPTwo"
                  >
                    Where is your office located?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqSPTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqSP"
                  >
                    <div className="accordion-body">
                      <p>
                        Our main office is located at Quezon Hill, Baguio City.
                      </p>
                      <p>
                        Exact address: #3 G/F Calle Uno Building, First Road
                        Quezon Hill, 2600 Baguio City, Philippines.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center mtop-60">
            <div className="col">
              <a href="#" className="btn-default-red">
                More Great FAQ
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="content-section content-testimonials white-bg">
        <div className="container k-container">
          <div className="row">
            <div className="col text-center">
              <h2 className="content-title f-45 blue">
                Frequently Asked Questions (Employers)
              </h2>
            </div>
          </div>
          <div className="row mtop-90">
            <div className="col">
              <h3 className="blue">Linkage Virtual Assistants</h3>
              <div className="accordion mtop-30" id="faqAP">
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqOne"
                    aria-expanded="true"
                    aria-controls="faqOne"
                  >
                    What is a VA and why do I need one?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAP"
                  >
                    <div className="accordion-body">
                      <p>
                        A virtual assistant (VA) is someone who has all of your
                        desired skills and works for you on your preferred
                        schedule. To ensure they're 100 percent productive, all
                        of their job tools, apps, and computer programs are
                        solely for your task requirements.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqTwo"
                    aria-expanded="false"
                    aria-controls="faqTwo"
                  >
                    What are the skill sets of your VA's ?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#faqAP"
                  >
                    <div className="accordion-body">
                      <p>
                        We employ virtual assistants with a diverse set of
                        abilities. Anything that may be done through the
                        Internet or over the phone can be handled by our
                        organization. Data entry, making or receiving phone
                        calls, web research, invoicing assistance, payroll
                        processing, recruiting assistance, email management,
                        website updates, MS Office assistance (Word, Excel, and
                        PowerPoint), real estate assistance (short sales, loss
                        mitigation, uploading MLS information to various
                        websites), social networking assistance (Twitter,
                        Facebook, and LinkedIn), and other tasks are among the
                        services we can provide.
                      </p>
                      <p>
                        Our capabilities are not limited to only these. As long
                        as you can provide training and a list of tasks that
                        need to be done, we can help you with pretty much
                        anything that can be done remotely.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqThree"
                    aria-expanded="false"
                    aria-controls="faqThree"
                  >
                    Where will my VA be located?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#faqAP"
                  >
                    <div className="accordion-body">
                      <p>
                        Our virtual assistants are based in the Philippines and
                        have passed our HR team's rigorous testing. Majority
                        have at least 2-3 years of experience working in the BPO
                        industry, primarily for clients based in the United
                        States. This guarantees that the VAs are exposed to and
                        knowledgeable about American culture, which is critical
                        to their performance as VAs for American clients.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqFour"
                    aria-expanded="false"
                    aria-controls="faqFour"
                  >
                    Is my VA fluent in the English language?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#faqAP"
                  >
                    <div className="accordion-body">
                      <p>
                        Our virtual assistants are fluent in English, not just
                        verbally but also in writing. You may rest confident
                        that, in addition to excellent communication skills,
                        they are also excellent listeners. There's no need to
                        keep repeating directions. This is something we excel
                        at.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqFive"
                    aria-expanded="false"
                    aria-controls="faqFive"
                  >
                    How well trained are your virtual assistants?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#faqAP"
                  >
                    <div className="accordion-body">
                      <p>
                        Before joining Linkage, our VAs must go through a
                        comprehensive selection process. Many of them have
                        worked in the business world and/or have a college
                        education.
                      </p>
                      <p>
                        Virtual Assistants can even train to become "Dedicated
                        Virtual Assistants" (DVAs) in order to move further in
                        our firm.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mtop-45">
            <div className="col">
              <h3 className="blue">Having a Dedicated VA</h3>
              <div className="accordion mtop-30" id="faqDVA">
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqDVAOne"
                    aria-expanded="true"
                    aria-controls="faqDVAOne"
                  >
                    What does having a dedicated VA entail?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqDVAOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqDVA"
                  >
                    <div className="accordion-body">
                      <p>
                        A dedicated VA is someone who will be your primary point
                        of contact on a regular basis. Simply treat your VA like
                        a regular employee and train them to accomplish things
                        the way you desire. Our virtual assistants are
                        well-versed in the majority of the common tasks that we
                        receive from our clients; nevertheless, because each
                        client has unique needs, we encourage that you spend
                        some time with your dedicated VA to discuss what you
                        need done and how you want it done. Spending some time
                        with your VA at the start is always beneficial in the
                        long term.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqDVATwo"
                    aria-expanded="false"
                    aria-controls="faqDVATwo"
                  >
                    Will my hired VA be working on weekends or on holidays?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqDVATwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#faqDVA"
                  >
                    <div className="accordion-body">
                      <p>
                        Your virtual assistant will work as long as you need
                        them to. Our virtual assistants work outside of normal
                        business hours. Do you need them to work on weekends or
                        even on US holidays for a unique project? That's not an
                        issue! Just let the VA know ahead of time that you'd
                        like them to work on certain days, and they'll be happy
                        to accommodate.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqDVAThree"
                    aria-expanded="false"
                    aria-controls="faqDVAThree"
                  >
                    What schedule will my VA follow?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqDVAThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#faqDVA"
                  >
                    <div className="accordion-body">
                      <p>
                        Your hired VA will work in the American time zone or in
                        whatever time zone of their customer is. Despite the
                        fact that they are physically located in the
                        Philippines, the time difference will not be an issue
                        because the VAs are accustomed to working at night or on
                        American time. Your VA will work with your schedule
                        because they are always adapting and coping with their
                        clients' needs and expectations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mtop-45">
            <div className="col">
              <h3 className="blue">Recruitment</h3>
              <div className="accordion mtop-30" id="faqRQ">
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqRQOne"
                    aria-expanded="true"
                    aria-controls="faqRQOne"
                  >
                    Will you hire people particularly to meet our needs? Or do
                    you already have a team of people in place?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqRQOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqRQ"
                  >
                    <div className="accordion-body">
                      <p>
                        Our main goal is to match the right clients with the
                        right employees in order to create a win-win situation
                        and a pleasant working environment. We normally hire
                        people that are precisely suited to your needs. We
                        usually have stand-by staff ready to start for more
                        general VA duties.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqRQTwo"
                    aria-expanded="true"
                    aria-controls="faqRQTwo"
                  >
                    How do I know that my business and customer details will be
                    safe?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqRQTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqRQ"
                  >
                    <div className="accordion-body">
                      <p>
                        Our business relies heavily on confidentiality. We
                        provide all of our clients with a legally binding NDA
                        (non-disclosure agreement). We take all security and
                        privacy concerns very seriously, and we expect our
                        employees to act with the utmost professionalism at all
                        times. On a need-to-know basis, Linkage shares
                        confidential information with its employees.
                        Furthermore, all VAs are obliged to sign NDAs
                        individually.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mtop-45">
            <div className="col">
              <h3 className="blue">Supervision</h3>
              <div className="accordion mtop-30" id="faqSV">
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqSVOne"
                    aria-expanded="true"
                    aria-controls="faqSVOne"
                  >
                    Is it possible for my VA to work during my preferred
                    hours/time zone?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqSVOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqSV"
                  >
                    <div className="accordion-body">
                      <p>
                        Yes. We can source for VA’s who are willing to work
                        depending on the work schedule you have specified.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqSVTwo"
                    aria-expanded="true"
                    aria-controls="faqSVTwo"
                  >
                    What if I need someone with flexible hours, instead of the
                    normal 9 to 5?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqSVTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqSV"
                  >
                    <div className="accordion-body">
                      <p>
                        Linkage realizes that a flexible approach to working
                        hours is often linked to the success of certain clients.
                        Our services are geared to accommodate your time
                        schedule, whatever the need.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mtop-45">
            <div className="col">
              <h3 className="blue">Signing Up</h3>
              <div className="accordion mtop-30" id="faqSU">
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqSUOne"
                    aria-expanded="true"
                    aria-controls="faqSUOne"
                  >
                    After I sign up, what happens next?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqSUOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqSU"
                  >
                    <div className="accordion-body">
                      <p>
                        Within 12 to 24 hours of signing up, one of our Project
                        Managers will contact you and assign you a dedicated VA
                        who, based on your needs, they believe will be the
                        greatest fit for you. After you sign up, you may usually
                        start sending your work the next day (within 24 hours).
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqSUTwo"
                    aria-expanded="true"
                    aria-controls="faqSUTwo"
                  >
                    What does the free account get me?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqSUTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqSU"
                  >
                    <div className="accordion-body">
                      <p>
                        You may post a job and see job applications for free. It
                        provides you a good indication of whether or not you'll
                        be able to discover someone suitable. You won't be able
                        to see or speak with applicants until you upgrade.
                      </p>
                      <p>
                        We don't provide a free trial for a reason. After you've
                        paid for an account, you'll be able to interview
                        workers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mtop-45">
            <div className="col">
              <h3 className="blue">Cancellations</h3>
              <div className="accordion mtop-30" id="faqCCS">
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCCSOne"
                    aria-expanded="true"
                    aria-controls="faqCCSOne"
                  >
                    If I commit, am I locked in to any contracts?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqCCSOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqCCS"
                  >
                    <div className="accordion-body">
                      <p>
                        If you are unhappy with the present VA, you may always
                        contact your Project Manager and share your issues. If
                        you let us know about it, we'll either replace them or
                        terminate your contract right away. You have nothing to
                        lose and everything to gain by taking this chance.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCCSTwo"
                    aria-expanded="true"
                    aria-controls="faqCCSTwo"
                  >
                    Can I cancel any time?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqCCSTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqCCS"
                  >
                    <div className="accordion-body">
                      <p>
                        There are no contracts, and you can upgrade, downgrade,
                        or cancel your subscription at any time before the end
                        of your current monthly cycle. Just send us an email to
                        support@linkage.ph or inform your Project Manager/VA of
                        the change, and we'll make the necessary changes to your
                        plan.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mtop-45">
            <div className="col">
              <h3 className="blue">Connecting With Your VAs</h3>
              <div className="accordion mtop-30" id="faqCVA">
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCVAOne"
                    aria-expanded="true"
                    aria-controls="faqCVAOne"
                  >
                    How will I communicate with my Virtual Assistant?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqCVAOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqCVA"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          We provide a vast range of communication options to
                          suit all client needs. Our aim is to make
                          communication easy and simple, just like they were
                          working in your own office. These include:
                        </li>
                        <li>
                          An office phone with a local number in ‘your’ city.
                          (at no additional charge to you)
                        </li>
                        <li>Skype</li>
                        <li>Email</li>
                        <li>
                          Online management tools such as Base Camp, SmartSheet
                          or others.
                        </li>
                        <li>
                          An office phone with a local number in ‘your’ city for
                          normal use (at no additional charge to you)
                        </li>
                        <li>
                          NB – VOIP phone cost has changed – Outgoing calls are
                          now rated.
                        </li>
                        <li>Skype or others</li>
                        <li>Email</li>
                        <li>
                          Online management tools that you use like Trello, Base
                          Camp or Smartsheet that you use
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCVATwo"
                    aria-expanded="true"
                    aria-controls="faqCVATwo"
                  >
                    Can we put our virtual assistant’s details on our website?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqCVATwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqCVA"
                  >
                    <div className="accordion-body">
                      <p>
                        Yes, you certainly can. In fact, we strongly advise it.
                        We recommend that you approach your new employee as
                        though he or she were any other new employee.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCVAThree"
                    aria-expanded="true"
                    aria-controls="faqCVAThree"
                  >
                    Can we issue our virtual assistant an email address?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqCVAThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqCVA"
                  >
                    <div className="accordion-body">
                      <p>
                        Yes, you certainly can. You might wish to create an
                        email header with your workplace information and
                        possibly a photo.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mtop-45">
            <div className="col">
              <h3 className="blue">Paying Your VAs</h3>
              <div className="accordion mtop-30" id="faqPVA">
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqPVAOne"
                    aria-expanded="true"
                    aria-controls="faqPVAOne"
                  >
                    How much should I pay my Filipino Virtual Assistant?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqPVAOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqPVA"
                  >
                    <div className="accordion-body">
                      <p>
                        The majority of Filipino VAs have earned a bachelor's
                        degree or higher.
                      </p>
                      <p>
                        Virtual assistant salaries range from $350-$450 per
                        month for entry-level positions, with experienced and
                        more specialized virtual assistants earning between $450
                        and $650 per month.
                      </p>
                      <p>
                        This is built on a database of over 100,000 virtual
                        assistants in Linkage.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mtop-45">
            <div className="col">
              <h3 className="blue">Rewards</h3>
              <div className="accordion mtop-30" id="faqRW">
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqRWOne"
                    aria-expanded="true"
                    aria-controls="faqRWOne"
                  >
                    Is it possible to compensate our virtual assistant for their
                    efforts?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqRWOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqRW"
                  >
                    <div className="accordion-body">
                      <p>
                        Yes, you certainly can. Our advice is to let us know how
                        much you'd like to donate, and we'll add it to your next
                        monthly invoice and send it to you in full.
                      </p>
                      <p>
                        Bonuses are not expected, but they are greatly
                        appreciated. We have our own bonus structure here at
                        linkage to reward their efforts.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mtop-45">
            <div className="col">
              <h3 className="blue">Culture</h3>
              <div className="accordion mtop-30" id="faqCT">
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqCTOne"
                    aria-expanded="true"
                    aria-controls="faqCTOne"
                  >
                    I keep hearing about this 13th Month Pay, what is it?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqCTOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqCT"
                  >
                    <div className="accordion-body">
                      <p>
                        In summary,the 13th Month Pay benefit in the Philippines
                        is a legislative and mandated benefit that companies are
                        required to pay to rank-and-file employees on a yearly
                        basis.
                      </p>
                      <p>
                        To put it simply, covered employees are entitled to an
                        extra compensation benefit equivalent to one-twelfth
                        (1/12) of their income for the same calendar year.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mtop-45">
            <div className="col">
              <h3 className="blue">Other FAQs</h3>
              <div className="accordion mtop-30" id="faqOFQ">
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqOFQOne"
                    aria-expanded="true"
                    aria-controls="faqOFQOne"
                  >
                    Do you have any clients or sorts of businesses that you
                    won't work with?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqOFQOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqOFQ"
                  >
                    <div className="accordion-body">
                      <p>
                        Yes, they do exist. We shall not promote clients or
                        enterprises that we believe are unethical or could
                        expose our employees or firm to disgrace or
                        embarrassment. In fact, we screen' our clients in the
                        same way that we do our employees! That is correct.
                        We're seeking for employees and clients that want to
                        work with us for a long time. That a result, it's
                        critical that we select clients with the same care as we
                        do our employees.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <a
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faqOFQTwo"
                    aria-expanded="true"
                    aria-controls="faqOFQTwo"
                  >
                    Why outsource to the Philippines?
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <div
                    id="faqOFQTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqOFQ"
                  >
                    <div className="accordion-body">
                      <p>
                        You may free up your on-shore team to focus on core
                        business activities by outsourcing to the Philippines,
                        and have the off-shore staff handle the manual processes
                        and administrative tasks. Outsourcing allows you to
                        expand your business quickly while also giving you the
                        freedom you require. It saves money on infrastructure,
                        office space, consumables, and human resources
                        functions. Outsourcing is not only cost-effective, but
                        it also allows you to expand your service offerings.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center mtop-60">
            <div className="col">
              <a href="#" className="btn-default-red">
                More Great FAQ
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="content-section content-testimonials light-grey-bg">
        <div className="container k-container">
          <div className="row">
            <div className="col text-center">
              <h2 className="content-title f-45 blue">
                Our Employers Love OnlineJobs
              </h2>
            </div>
          </div>
          <div className="row mtop-90">
            <div className="col">
              <div className="tm-content">
                <span className="quote">“</span>
                <p>
                  I found my job through Linkage! I’ve been working for my
                  employer for 6 months now and I can’t be any happier!
                </p>
                <div className="tm-profile">
                  <img src="../../imgs/tm1.png" alt="Jobseekers Name" />
                  <div className="tm-name">
                    <p>Nathan Gomez</p>
                    <span className="tm-star">
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="tm-content">
                <span className="quote">“</span>
                <p>
                  I was about to give up. Getting a job is really a challenge.
                  But Linkage made it easy for me. All I had to do was create my
                  profile and to make sure I had everything in place. I started
                  looking for jobs. I applied to many of them and some of the
                  employers even replied within a day!
                </p>
                <div className="tm-profile">
                  <img src="../../imgs/tm2.png" alt="Jobseekers Name" />
                  <div className="tm-name">
                    <p>Liza Abellera</p>
                    <span className="tm-star">
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="tm-content">
                <span className="quote">“</span>
                <p>
                  Using Linkage is one of the best decisions I’ve made for my
                  career. I now work as a VA for a US based company. It helped
                  me connect with employers that are very accommodating!
                </p>
                <div className="tm-profile">
                  <img src="../../imgs/tm1.png" alt="Jobseekers Name" />
                  <div className="tm-name">
                    <p>Mike Reyes</p>
                    <span className="tm-star">
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section how-tp white-bg">
        <div className="container k-container">
          <div className="row">
            <div className="col text-center">
              <h2 className="content-title f-45 blue">RESOURCES</h2>
              <p className="content-sub-title dark-gray">
                Over 1,000 Job Applicants Available For Employment
              </p>
            </div>
          </div>
          <div className="row row-shadow row-blog">
            <div className="col">
              <img
                src="https://linkagekoworks.viewourdesign.info/storage/images/621654255432-img-a1.jpg"
                alt="Why Filipinos are World-Renown Virtual Assistants"
              />
            </div>
            <div className="col-9">
              <h3>
                <a href="https://linkagekoworks.viewourdesign.info/resources/why-filipinos-are-world-renown-virtual-assistants">
                  Why Filipinos are World-Renown Virtual Assistants
                </a>
              </h3>
              <p>
                The internet has forever changed the world. Our means of
                communication, forms of entertainment, and means of buying
                things have some element of the internet present in them. The
                way we do our jobs is no exception to this. Hence, the rise of
                remote workers. The idea of working for a client thousands...
              </p>
              <a
                href="https://linkagekoworks.viewourdesign.info/resources/why-filipinos-are-world-renown-virtual-assistants"
                className="btn-default-red text-center"
              >
                Read On
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
