import React from "react";

const Pagination = ({ totalPages, paginate }) => {
  let pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  return (
    <nav>
      <ul className="pagination justify-content-center">
        {pages.map((number) => (
          <li key={number} className="page-item">
            <a
              href="#"
              className="page-link"
              // Prevent default to stop the page reload
              onClick={(e) => {
                e.preventDefault();
                paginate(number);
              }}
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
