import React from "react";

const SubscribeNow = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement subscription logic here
    console.log("Subscription submitted");
  };

  return (
    <div className="subscribe-now mtop-45">
      <h3 className="blue">Subscribe Now!</h3>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Enter your Email"
          required
        />
        <input type="submit" className="btn-default-red" value="Subscribe" />
      </form>
    </div>
  );
};

export default SubscribeNow;
