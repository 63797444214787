// NotFound.jsx
import React from "react";

function NotFound() {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>404</h1>
      <p>Page Not Found</p>
    </div>
  );
}

export default NotFound;
