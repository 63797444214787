import React from "react";

const BlogCategory = () => {
  return (
    <div className="blog-category">
      <h3 className="blue">Category</h3>
      <ul className="category-list">
        <li>
          <a href="#">Uncategorized</a>
        </li>
        {/* Add more categories as needed */}
      </ul>
    </div>
  );
};

export default BlogCategory;
