import React from "react";

const ReplyForm = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement submission logic here
    console.log("Form submitted");
  };

  return (
    <div className="reply-form mtop-45">
      <h3 className="blue f-25">Leave a Reply</h3>
      <div className="list-of-comment"></div>
      <form onSubmit={handleSubmit}>
        <div className="kform-group">
          <textarea
            name="comment"
            id="comment"
            rows="10"
            placeholder="Leave your Comment"
            required
          ></textarea>
        </div>
        <div className="kform-group colstwo">
          <div className="col relative px-0 mright-23">
            <input
              type="text"
              className="kfield gray f-border"
              name="name"
              placeholder="Enter your Name"
            />
          </div>
          <div className="col relative px-0 mleft-23">
            <input
              type="email"
              className="kfield gray f-border"
              name="email"
              placeholder="Enter your Email"
            />
          </div>
        </div>
        <div className="kform-group">
          <input
            type="text"
            className="kfield gray f-border"
            name="company"
            placeholder="Enter your Company Name"
          />
        </div>
        <div className="kform-group">
          <input type="hidden" name="rid" value="62" />
          <input
            type="submit"
            className="btn-default-red"
            value="Send Message"
          />
        </div>
      </form>
    </div>
  );
};

export default ReplyForm;
