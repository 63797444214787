import BlogCategory from "./components/BlogCategory";
import BlogContent from "./components/BlogContent";
import BlogShareData from "./components/BlogShareData";
import LikeSection from "./components/LikeSection";
import NavigationBlog from "./components/NavigationBlog";
import RecentBlog from "./components/RecentBlog";
import ReplyForm from "./components/ReplyForm";
import ResourceSearch from "./components/ResourceSearch";
import SubscribeNow from "./components/SubscribeNow";

const Blog = () => {
  return (
    <>
      {" "}
      <section class="banner-section-sa banner-page banner-blog">
        <div class="container k-container">
          <div class="row">
            <div class="col text-center">
              <div class="banner-content">
                <p class="banner-title-sub red-bg">BLOG</p>
                <h1 class="banner-title" style={{ color: "#0A73B8" }}>
                  Why Filipinos are World-Renown Virtual Assistants
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="content-section white-bg resources-blog">
        <div class="container k-container">
          <div class="row">
            <div class="col col-8">
              <LikeSection />
              <BlogContent />
              <BlogShareData />
              <NavigationBlog />
              <ReplyForm />
            </div>
            <div class="col col-4">
              <ResourceSearch />
              <BlogCategory />
              <RecentBlog />
              <SubscribeNow />
            </div>
          </div>
        </div>
      </section>
      <div
        class="modal fade"
        id="shareModal"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="shareModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content ptb-40">
            <div class="modal-body text-center">
              <h1 class="f-35 b blue" id="shareModalLabel">
                Share
              </h1>
              <h3 class="red">
                Why Filipinos are World-Renown Virtual Assistants
              </h3>
              <ul class="share-modal">
                <li>
                  <a
                    onClick="MyWindow=window.open('https://www.facebook.com/sharer/sharer.php?u=https://linkagekoworks.viewourdesign.info/resources/why-filipinos-are-world-renown-virtual-assistants','MyWindow','width=900,height=600'); return false;"
                    href="#"
                  >
                    <i class="fa fa-facebook-official" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    onClick="MyWindow=window.open('https://twitter.com/intent/tweet?text=Why+Filipinos+are+World-Renown+Virtual+Assistants&url=https://linkagekoworks.viewourdesign.info/resources/why-filipinos-are-world-renown-virtual-assistants','MyWindow','width=900,height=600'); return false;"
                    href="#"
                  >
                    <i class="fa fa-twitter-square" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    onClick="MyWindow=window.open('https://www.linkedin.com/sharing/share-offsite?mini=true&url=https://linkagekoworks.viewourdesign.info/resources/why-filipinos-are-world-renown-virtual-assistants&title=Why+Filipinos+are+World-Renown+Virtual+Assistants&summary=','MyWindow','width=900,height=600'); return false;"
                    href="#"
                  >
                    <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
