import React from "react";
import likeIcon from "../../../imgs/icons/like-icon.png"; // Adjust the path as necessary

const BlogShareData = () => {
  return (
    <div className="blog-share-data">
      <div className="row">
        <div className="col">
          <span className="k-like">
            <img src={likeIcon} alt="Likes" />0 People Like this.
          </span>
        </div>
      </div>
    </div>
  );
};

export default BlogShareData;
