import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchData } from "../../../../api/api";

const BookmarkedWorkers = () => {
  const [bookmarkedWorkers, setBookmarkedWorkers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getBookmarkedWorkers = async () => {
      setIsLoading(true);
      try {
        const response = await fetchData(
          "EmployerRoutes/getPinnedEmployees",
          "GET"
        );
        if (response && response.pinnedEmployees) {
          // Sort by the `created` field and take the latest 2
          const sortedByDate = response.pinnedEmployees
            .sort((a, b) => new Date(b.created) - new Date(a.created))
            .slice(0, 2);
          setBookmarkedWorkers(sortedByDate);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch bookmarked workers:", error);
        setIsLoading(false);
      }
    };

    getBookmarkedWorkers();
  }, []);

  const extractSkills = (skillsObject) => {
    let allSkills = [];

    Object.values(skillsObject).forEach((category) => {
      if (Array.isArray(category)) {
        category.forEach((skill) => {
          if (skill && skill.name) allSkills.push(skill.name);
        });
      }
    });

    allSkills = allSkills.sort(() => 0.5 - Math.random());

    return allSkills.slice(0, 10);
  };

  const navigateToProfile = (worker) => {
    navigate(`/ViewOneEmployee`, {
      state: { user: worker.employee },
    });
  };

  console.log(bookmarkedWorkers);

  return (
    <div className="your-bookmarks mtop-30">
      <h2 className="f-35 blue mbot-40">Recent Bookmarked Workers</h2>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        bookmarkedWorkers.map((worker, index) => (
          <div className="bookmark-content" key={index}>
            <div
              className="bookmark-header"
              onClick={() => navigateToProfile(worker)}
              style={{ cursor: "pointer" }}
            >
              <h4 className="white">{worker.employee.fullname}</h4>
              <h5 className="white">
                {worker.employee.employeeProfile.job_title}
              </h5>
            </div>

            <div className="row align-items-center bookmark-data">
              <div className="col-2">
                <img
                  src="https://linkagekoworks.viewourdesign.info/storage/images/placeholder-user.png"
                  alt={worker.employee.fullname}
                />
              </div>
              <div className="col-10">
                <div className="row">
                  <div className="col">
                    <h6 className="blue">Expected Salary</h6>
                    <p className="b">
                      ${worker.employee.employeeProfile.salary}
                    </p>
                  </div>
                  <div className="col">
                    <h6 className="blue">Education</h6>
                    <p className="up-val">
                      {worker.employee.employeeProfile.education}
                    </p>
                  </div>
                  <div className="col">
                    <h6 className="blue">Experience</h6>
                    <p className="b">
                      {worker.employee.employeeProfile.experience} Years
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h6 className="blue">Top Skills</h6>
                    <ul className="tskills">
                      {extractSkills(worker.employee.skill).map(
                        (skill, index) => (
                          <li key={index} className="b">
                            {skill}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h6 className="blue">Notes:</h6>
                    <p>{worker.notes}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
      <div className="row">
        <div className="col text-center">
          <Link to="/ViewAllMarkWorkers" className="btn-default-red small-btn">
            View All
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BookmarkedWorkers;
