import React from "react";
import { Link } from "react-router-dom";

const TableHeader = ({
  searchQuery,
  rowsToShow,
  onSearchChange,
  onRowsChange,
  onSort,
  sortConfig,
}) => {
  const handleRowsChange = (e) => {
    if (e && e.target) {
      const numberOfRows = Math.max(1, parseInt(e.target.value, 10) || 1);
      onRowsChange(numberOfRows); // Pass the number directly
    }
  };
  const getSortIcon = (field) => {
    if (sortConfig.field !== field) return "fa-sort";
    return sortConfig.direction === "asc" ? "fa-sort-up" : "fa-sort-down";
  };

  return (
    <thead>
      <tr className="bg-white border-bottom">
        <td className="px-0 align-middle" colSpan="6">
          <div className="d-flex flex-column flex-xl-row">
            {/* Search Form */}
            <div className="flex-fill">
              <div className="flex-fill pr-xl-3 py-1 searching">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text text-secondary"
                      style={{ fontSize: "25px" }}
                    >
                      <i className="fa fa-search"></i>
                    </span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="search"
                    value={searchQuery}
                    placeholder="Search by: Job Title, ID Score, Type of Employment, Status"
                    aria-label="Search by: Job Title, ID Score, Type of Employment, Status"
                    onChange={onSearchChange} // Add this
                  />
                </div>
              </div>
            </div>

            {/* Rows Number Form */}
            <div className="d-flex justify-content-between">
              <div className="px-xl-3 py-1 rows-number-definition">
                <input type="hidden" name="search" value="" />
                <input type="hidden" name="sort_by" value="" />
                <input type="hidden" name="sort_dir" value="" />
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text text-secondary"
                      style={{ fontSize: "25px" }}
                    >
                      <i className="fa fa-list-ol"></i>
                    </span>
                  </div>
                  <input
                    className="form-control"
                    type="number"
                    name="rows"
                    value={rowsToShow}
                    placeholder="Number of rows"
                    aria-label="Number of rows"
                    onChange={handleRowsChange} // Use the local handler
                    min="1" // HTML5 minimum attribute
                  />
                </div>
              </div>

              {/* Create Action */}
              <div className="d-flex align-items-center pl-3 py-1 create-action">
                <Link
                  to="/DEmployer/postnewjob"
                  className="btn" // Removed btn-success for custom styling
                  style={{ backgroundColor: "#007bff", color: "white" }} // Example: Blue background with white text
                  title="Create"
                >
                  <i
                    className="fa fa-plus-circle fa-fw"
                    style={{ backgroundColor: "#007bff", color: "white" }}
                  ></i>{" "}
                  Create
                </Link>
              </div>
            </div>
          </div>
        </td>
      </tr>

      {/* Column Headers */}
      <tr className="bg-light border-bottom">
        <th onClick={() => onSort("jobTitle")} style={{ cursor: "pointer" }}>
          <span>
            Job Title <i className={`fa ${getSortIcon("jobTitle")}`}></i>
          </span>
        </th>
        <th onClick={() => onSort("idProof")} style={{ cursor: "pointer" }}>
          <span>
            ID Score <i className={`fa ${getSortIcon("idProof")}`}></i>
          </span>
        </th>
        <th
          onClick={() => onSort("employmentType")}
          style={{ cursor: "pointer" }}
        >
          <span>
            Type of Employment{" "}
            <i className={`fa ${getSortIcon("employmentType")}`}></i>
          </span>
        </th>

        <th onClick={() => onSort("status")} style={{ cursor: "pointer" }}>
          <span>
            Status <i className={`fa ${getSortIcon("status")}`}></i>
          </span>
        </th>

        <th onClick={() => onSort("applicants")} style={{ cursor: "pointer" }}>
          <span>
            Applicants <i className={`fa ${getSortIcon("applicants")}`}></i>
          </span>
        </th>

        {/* Actions Column Header */}
        <th className="text-right align-middle" scope="col">
          Actions
        </th>
      </tr>
    </thead>
  );
};

export default TableHeader;
