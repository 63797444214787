import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const ApplicantsTable = ({ applicants, handleUpdateStatusClick }) => {
  const navigate = useNavigate();

  const navigateWithState = (applicant) => {
    navigate("/ViewOneEmployee", { state: { user: applicant } });
  };

  return (
    <table className="table table-hover shadow-sm">
      <thead className="bg-gradient-custom">
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Job Title</th>
          <th scope="col">Experience</th>
          <th scope="col">Contact</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        {applicants.map((applicant, index) => (
          <tr key={index}>
            <td>{applicant.fullname}</td>
            <td>{applicant.email}</td>
            <td>{applicant.employeeProfile?.job_title ?? "N/A"}</td>
            <td>{applicant.employeeProfile?.experience ?? "N/A"}</td>
            <td>{applicant.employeeProfile?.contact ?? "N/A"}</td>
            <td>
              <span
                title="View Details"
                className="icon-action"
                onClick={() => navigateWithState(applicant)}
              >
                <FontAwesomeIcon icon={faEye} />
              </span>
              <span
                title="Update Status"
                className="icon-action ml-2"
                onClick={() => handleUpdateStatusClick(applicant._id)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ApplicantsTable;
