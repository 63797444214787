import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBill,
  faClock,
  faBook,
  faBriefcase,
  faThumbtack,
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { motion, AnimatePresence } from "framer-motion";
import { fetchData } from "../../../api/api";

const JobResult = ({ job }) => {
  const { currentUser } = useContext(UserContext);
  const [isPinned, setIsPinned] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [note, setNote] = useState("");
  const navigate = useNavigate();
  const { employeeProfile, skill } = job;
  const ratedSkills = Object.values(skill ?? {})
    .flat()
    .filter((s) => s.rating > 0);

  const displayedSkills = ratedSkills
    .sort(() => 0.5 - Math.random())
    .slice(0, 3);

  const imageSrc = employeeProfile?.imageData
    ? `data:image/jpeg;base64,${employeeProfile.imageData}`
    : "../../imgs/placeholder-user.png";

  const navigateWithState = () => {
    navigate("/ViewOneEmployee", { state: { user: job } });
  };

  const handlePinClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleNoteSubmit = async () => {
    const pinData = {
      user: currentUser._id,
      employee: job._id,
      notes: note,
    };

    try {
      const response = await fetchData(
        "EmployerRoutes/createPinEmployee", // Adjust this endpoint according to your server's route
        "POST",
        pinData
      );

      if (response.message === "Employee pinned successfully") {
        setIsPinned(true);
      } else {
        console.error("Failed to pin employee:", response.message);
      }
    } catch (error) {
      console.error("Error pinning employee:", error);
    }

    setShowModal(false);
  };

  const modalVariants = {
    hidden: { y: "-100vh", opacity: 0 },
    visible: {
      y: "0",
      opacity: 1,
      transition: { type: "spring", stiffness: 100 },
    },
    exit: { y: "-100vh", opacity: 0, transition: { duration: 0.5 } },
  };

  useEffect(() => {
    const checkPinnedStatus = async () => {
      if (currentUser && currentUser.role === "employer") {
        try {
          const response = await fetchData(
            "EmployerRoutes/getPinnedEmployees", // Adjust this endpoint as necessary
            "GET"
          );
          if (response.pinnedEmployees) {
            // Assuming response.pinnedEmployees is an array of objects where each object has an employee field
            const pinned = response.pinnedEmployees.some((pinnedEmployee) =>
              pinnedEmployee.employee._id
                ? pinnedEmployee.employee._id === job._id
                : pinnedEmployee.employee === job._id
            );
            setIsPinned(pinned);
          }
        } catch (error) {
          console.error("Error fetching pinned employees:", error);
        }
      }
    };

    checkPinnedStatus();
  }, [currentUser, job._id]);

  const unpinEmployee = async () => {
    try {
      const pinData = {
        employeeId: job._id, // Assuming job._id is the ID of the employee/job being unpinned
      };
      const response = await fetchData(
        "EmployerRoutes/unpinEmployee", // Adjust this endpoint according to your server's route
        "DELETE",
        pinData
      );

      if (response.message === "Employee unpinned successfully") {
        console.log("Success:", response);
        setIsPinned(false); // Update the state to reflect that the job is no longer pinned
        // Optionally, refresh the list of jobs or perform other actions as needed
      } else {
        console.error("Failed to unpin employee:", response.message);
        // Handle failure (e.g., show an error message)
      }
    } catch (error) {
      console.error("Error unpinning employee:", error);
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <div className="result-container">
      <div className="result-header">
        <h2 className="job-title">{employeeProfile?.job_title}</h2>
        <div
          className="d-flex justify-content-end h6"
          style={{ cursor: "pointer" }}
        >
          {currentUser?.role === "employer" && (
            <span
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={isPinned ? unpinEmployee : handlePinClick}
            >
              {isPinned ? (
                <>
                  <FontAwesomeIcon icon={faThumbtack} /> Unpin
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faThumbtack} /> Pin
                </>
              )}
            </span>
          )}
          <span onClick={navigateWithState}>View Profile</span>
        </div>
      </div>
      <div className="result-content">
        <div className="row">
          <div className="col-3">
            <div className="result-img">
              <img src={imageSrc} alt={employeeProfile?.job_title} />
            </div>
          </div>
          <div className="col-3">
            <div className="result-meta">
              <h3>
                <FontAwesomeIcon icon={faMoneyBill} /> Expected Salary
              </h3>
              <span className="up-val">${employeeProfile?.salary}</span>
            </div>
            <div className="result-meta">
              <h3>
                <FontAwesomeIcon icon={faClock} /> Employment Type
              </h3>
              <span className="up-val">
                {employeeProfile?.employment_status}
              </span>
            </div>
          </div>
          <div className="col-3">
            <div className="result-meta">
              <h3>
                <FontAwesomeIcon icon={faBook} /> Education
              </h3>
              <span className="up-val">{employeeProfile?.education}</span>
            </div>
            <div className="result-meta">
              <h3>
                <FontAwesomeIcon icon={faBriefcase} /> Experience
              </h3>
              <span className="up-val">
                {employeeProfile?.experience} years
              </span>
            </div>
          </div>
          <div className="col-3">
            {displayedSkills.map((skillItem) => (
              <div className="result-skill" key={skillItem._id}>
                <h3>{skillItem.name}</h3>
                {[...Array(skillItem.rating)].map((_, i) => (
                  <span key={i} className="fa fa-star"></span>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col result-summary">
            <p>{employeeProfile?.summary}</p>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {showModal && (
          <div className="pin-employee-modal">
            <motion.div
              className="pin-employee-modal-content"
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <span className="pin-employee-close" onClick={handleModalClose}>
                &times;
              </span>
              <h2>Add a Note</h2>
              <textarea
                className="pin-employee-textarea"
                value={note}
                onChange={handleNoteChange}
                placeholder="Type your note here..."
              ></textarea>
              <button
                className="pin-employee-button"
                onClick={handleNoteSubmit}
              >
                Submit Note
              </button>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default JobResult;
